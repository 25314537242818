import axios, { AxiosInstance } from "axios"


const getBaseUrlFromHost = (): string => {
    const host = window.location.host
    var subdomains = host.split(".")
    subdomains[0] += "-be"

    return `https://${subdomains.join(".")}`
}


export const authorizedApiClient = (token: string): AxiosInstance => axios.create({
    baseURL: getBaseUrlFromHost(),
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

export const formDataApiClient = (token: string): AxiosInstance => axios.create({
    baseURL: getBaseUrlFromHost(),
    headers: {
        "Authorization": `Token ${token}`
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

export const unauthorizedApiClient = (headers?: { [key: string] : string}): AxiosInstance => axios.create({
    baseURL: getBaseUrlFromHost(),
    headers: {
        "Content-Type": "application/json",
        ...headers
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

export const noCredentialApiClient = (): AxiosInstance => axios.create({
    baseURL: getBaseUrlFromHost(),
    headers: {
        "Content-Type": "application/json"
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

export const plainClient = (): AxiosInstance => axios.create({})